.training {
    background: #f3f6f999;
    padding: 16px;
    height: calc(100% - 32px);
}

.training > * {
    flex-grow: 1;
    display: flex;
    justify-content: center;
}

.training > * > * {
    display: flex;
    flex-direction: column;
    align-items: center;
}