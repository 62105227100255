.resizer {
    background-color: var(--color3)
}
.resizer {
    font-size: 24px;
    width: 20px;
    height: 20px;

    cursor: nwse-resize;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #ffffff;

    position: absolute;
    right: 1px;
    margin-top: 9px;
}
.resizer::after {
    content: '\2921'
}
.resizableDialog {
    border-radius: 4px;
    position: absolute;
    overflow: hidden;
    z-index: 100000;
    width: min-content;
}
.resizableDialog > * {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}
.resizableDialog:hover *, .resizableDialog.inProcess * {
    opacity: 1;
    transition: opacity 0s;
}

.draggier {
    cursor: move;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color1)
}

.inProcessBack {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1;
    display: none;
}
.inProcess .inProcessBack {
    display: block;
}

.loading {
    position: absolute;
    top: 50%;
    left: calc(50% - 20px);
}
.back {
    border: solid 1px #000000;
    background: #ffffffdd;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}