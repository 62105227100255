:global(.MuiDialog-container) > :global(.MuiPaper-root) > :global(.MuiButtonBase-root) {
    position: absolute;
    top: 8px;
    right: 8px;
}
.maxHeight {
    padding: 0;
    margin: 16px;
}
.maxHeight > :global(.MuiDialog-container) > :global(.MuiPaper-root) {
    height: 100%;
    width: 100%;
    max-height: 100%;
    margin: 0;
}
.maxHeight > :global(.MuiDialog-container) > :global(.MuiPaper-root) > :global(.MuiDialogContent-root) {
    padding: 0;
    border-bottom: none;
}