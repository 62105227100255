.vocabulary {
    display: flex;
    flex-direction: column;
    margin: 16px;
    overflow: hidden;
    height: calc(100% - 32px);
}
.vocabulary> :global(.MuiButtonBase-root) {
    margin-bottom: 8px;
    background: var(--color2);
    width: fit-content;
}
.vocabulary> :global(.MuiButtonBase-root:hover) {
    background: var(--color2);
}
.vocabularySearch {
    position: relative;
}
.vocabularySearch :global(.MuiSvgIcon-root) {
    padding: 0 16px;
    height: 100%;
    position: absolute;
}
.vocabularySearch input {
    font: inherit;
    padding: 8px 0 8px 42px;
    width: calc(100% - 46px);
}
.vocabulary table {
    border-collapse: collapse;
    margin-top: 8px;
    height: 100%;
    display: block;
    overflow: auto;
    border: none;
}
.vocabulary table td {
    border: solid 1px #9a9a9a;
    padding-left: 8px;
}
.vocabulary table td:nth-child(1), .vocabulary table td:nth-child(4) {
    width: 1%;
    padding: 0;
}
.vocabulary table :global(.MuiButtonBase-root) {
    min-width: 0;
}
.vocabulary table td:nth-child(4) :global(.MuiButtonBase-root) {
    color: var(--color2)
}

