.videoHeader {
    height: 61px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
}

.videoHeader .back {
    height: 100%;
    width: 100%;
    background: #000000;
    opacity: 0.4;
}

.videoHeader .closeButton {
    position: absolute;
    top: 8px;
    right: 8px;
    background: #ffffff;
    border: solid;
}

.videoHeader .closeButton:hover {
    background: #ffffff;
}