.drawer > :global(.MuiPaper-root) {
    width: inherit;
    box-sizing: border-box
}

.userChip {
    max-width: calc(100% - 36px)!important;
}

.userChip :global(.MuiChip-label) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}