.vocabularyArea {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    border-top: solid 1px #000000;
    align-items: center;
}

.vocabularyArea :global(.MuiButtonBase-root) {
    background-color: var(--color2) !important
}

.transArea {
    display: flex;
    gap: 4px;
    align-items: center;
    flex-wrap: wrap;
}

.transArea svg {
    cursor: pointer;
}