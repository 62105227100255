.login {
    display: flex!important;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 20px)
}

.paper {
    padding: 20px;
    max-width: 400px;
    width: 100%;
    text-align: center;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
