.words {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow-y:scroll;
    width: fit-content;
    padding-bottom: 4px;
    padding-right: 4px;
}
.word {
    user-select: none;
    border-radius: 10px;
    padding: 0 8px 8px 8px;
    margin-left: 4px;
    margin-top: 4px;
    background: #ffffffdd;
    cursor: pointer;
}
.word.selected {
    background: #51b93a;
}