html, body, #root {
    width: 100%;
    top: 0;
    left: 0;
    margin: 0;
    height: 100vh;
    padding: 0;

    position: fixed;
    overflow: hidden;
    touch-action: none;
    background: #ffffff;
}

:root {
    --color1: #1769aa;
    --color2: #f50057;
    --color3: #2196f3;
}













