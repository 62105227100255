.admin {
    margin: 16px;
}

.admin h1 {
    text-align: center;
}

.admin table {
    border-collapse: collapse;
    width: 100%;
}

.admin td {
    border: solid 1px #9a9a9a;
    padding: 4px;
}