.recognize {
    display: flex;
    height: 40px;
    flex-direction: column;
    align-items: center;
    margin: 0 0 30px 0;
}

.recognize > div {
    width: 100px;
}

.visualisation {
    width: 98px;
    height: 20px;
    border: solid 1px #e6e6e6;
}