.appBar {
    flex-grow: 1
}
.appBar > :global(.MuiPaper-root) {
    z-index: 1300;
    height: 64px;
    justify-content: center;
}
.accountOpenIcon {
    display: flex;
    align-items: center;
}

.logo {
    font-size: 1.25rem;
    font-family: monospace;
    font-weight: bold;
    letter-spacing: 0.3rem;
}
.logo a {
    color: inherit;
    text-decoration: none;
}
.logo a div{
    display: flex;
    gap: 8px;
}

.form {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 16px;
}
.form form {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    margin: 0;
    max-width: 700px;
}
.form form > *:nth-child(1) {
    background-color: #ffffff;
    opacity: 0.8;
    border-radius: 4px;
    width: 100%;
}
.form form > button {
    padding: 0 14px;
    min-height: 48px;
    min-width: 48px;
    background-color: var(--color2);
}
.form form > button:hover {
    background-color: var(--color2);
}
.form form > button > :global(.MuiButton-startIcon) {
    margin: 0;
}
.formEx {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.formExContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 16px;
}
